

























import DraftActions from '@/builder/mixins/DraftActions'
import { DraftActionsType } from '@/calendesk/models/DraftActionsType'
import mixins from 'vue-typed-mixins'
import { mapGetters } from 'vuex'
export default mixins(DraftActions).extend({
  name: 'PagesSelect',
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      selectedPages: Array<string>(),
      pages: Array<Record<string, any>>(),
      mode: DraftActionsType.SECTION
    }
  },
  computed: {
    ...mapGetters({
      draftPages: 'builder/getDraftPages',
      selectedSection: 'builder/getSelectedSection'
    })
  },
  watch: {
    selectedPages (pages: Array<string>): void {
      this.updateConfigurationProperty(this.data.key, pages)
    },
    'selectedSection.configuration' (): void {
      this.selectedPages = this.selectedSection.configuration[this.data.key]
    },
    data () {
      this.selectedPages = this.selectedSection.configuration[this.data.key]
    }
  },
  mounted () {
    this.selectedPages = this.selectedSection.configuration[this.data.key]
  }
})
